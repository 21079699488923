import { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { useStore } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { calculationStore } from "../../stores/calculationStore"
import { ClientConfig } from "../../services/clientconfig"
import { createApiPayload } from "./leafletsService"

function getDefaultState() {
  return {
    isLoading: true,
    priceOne: 0,
    totalPrice: 0,
  }
}

export function useCalculationPrice() {
  const { state: formState, setContactUs } = useStore(
    calculationStore,
    useShallow(s => ({
      state: s.state,
      setContactUs: s.setContactUs,
    })),
  )

  const [state, setState] = useState(getDefaultState())

  const loadDataDebounced = useCallback(
    debounce(formData => {
      const payload = createApiPayload(formData)

      fetch(`${ClientConfig.apiUrl}/api/calc/leaflets`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      })
        .then(resp => {
          if (resp.status === 409) {
            setContactUs(true)
            setState(getDefaultState())
            return
          }
          if (!resp.ok) {
            return
          }
          return resp.json()
        })
        .then(data => {
          if (!data) {
            setState(getDefaultState())
            return
          }
          setContactUs(false)
          setState(Object.assign(data, { isLoading: false }))
        })
    }, 700),
    []
  )

  useEffect(() => {
    setState(getDefaultState())
    loadDataDebounced(formState)
  }, [formState])

  return state
}
