import React, { FC } from "react"
import { useStore } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { CalculationBtn } from "./calculationBtn"
import { calculationStore, StateProperties } from "../../stores/calculationStore"

const InputName = StateProperties.PaperType;

const BUTTONS = [
  { value: "bright_white_smooth_115g", label: "115\u00A0g/m2", desc: ["Vysoká bělost pro perfektní reprodukci barev", "Excelentní matný povrch (možnost i lesk)", "Spolehlivý při oboustranném tisku", "100% ECF buničina bělená bez použití chlóru"] },
  { value: "bright_white_smooth_130g", label: "130\u00A0g/m2", desc: ["Vysoká bělost pro perfektní reprodukci barev", "Excelentní matný povrch (možnost i lesk)", "Spolehlivý při oboustranném tisku", "100% ECF buničina bělená bez použití chlóru"] },
  { value: "bright_white_smooth_150g", label: "150\u00A0g/m2", desc: ["Vysoká bělost pro perfektní reprodukci barev", "Excelentní matný povrch (možnost i lesk)", "Spolehlivý při oboustranném tisku", "100% ECF buničina bělená bez použití chlóru"] },
  { value: "bright_white_smooth_200g", label: "200\u00A0g/m2", desc: ["Vysoká bělost pro perfektní reprodukci barev", "Excelentní matný povrch (možnost i lesk)", "Spolehlivý při oboustranném tisku", "100% ECF buničina bělená bez použití chlóru"] },
  { value: "bright_white_smooth_300g", label: "300\u00A0g/m2", desc: ["Vysoká bělost pro perfektní reprodukci barev", "Excelentní matný povrch (možnost i lesk)", "Spolehlivý při oboustranném tisku", "100% ECF buničina bělená bez použití chlóru"] },
]

export const CalculationPaperType: FC = () => {
  const { checked, setProperty } = useStore(
    calculationStore,
    useShallow(s => ({
      checked: s.state[InputName],
      setProperty: s.setProperty,
    }))
  )

  const handleChange = (value: string) => {
    setProperty(InputName, value)
  }

  return (
    <div className="section-items paper-type">
      {BUTTONS.map(({ value, label, desc }) => (
        <CalculationBtn
          key={`${InputName}-${value}`}
          id={`${InputName}-${value}`}
          name={InputName}
          value={value}
          isActive={value === checked}
          onChange={handleChange}
          className="btn-radio-simple-item"
        >
          <span className="label">{label}</span>
          <ul>
            {desc.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </CalculationBtn>
      ))}
    </div>
  )
}
