import React, { forwardRef } from "react";
import { useStore } from "zustand";
import classNames from "classnames";
import { useShallow } from "zustand/react/shallow";
import { calculationStore, DifferentQuantityValue, StateProperties } from "../../stores/calculationStore";
import { CalculationBtn } from "./calculationBtn";

const InputName = StateProperties.PaperQuantity;
const PropertyCustomQuantity = StateProperties.PaperQuantityCustom;

const BUTTONS = [
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '300', label: '300' },
    { value: '400', label: '400' },
    { value: '500', label: '500' },
    { value: DifferentQuantityValue, label: 'jiné' },
];

type Props = {}

export const CalculationQuantity = forwardRef<HTMLDivElement, Props>((_, ref) => {
    const { checked, customValue, customValueError, contactUs, setProperty, resetError } = useStore(
        calculationStore,
        useShallow(s => ({
            contactUs: s.contactUs,
            checked: s.state[InputName],
            customValue: s.state[PropertyCustomQuantity],
            customValueError: s.state[StateProperties.PaperQuantityCustomError],
            setProperty: s.setProperty,
            resetError: s.resetError,
        }))
    )

    const handleChange = (value: string) => {
      setProperty(InputName, value)
    }

    return (
        <div ref={ref} className="quantity">
            <div className="section-items">
                {BUTTONS.map(({ value, label }) => (
                    <CalculationBtn
                        key={`${InputName}-${value}`}
                        id={`${InputName}-${value}`}
                        name={InputName}
                        value={value}
                        isActive={value === checked}
                        onChange={handleChange}
                        className="btn-radio-simple-item"
                    >
                        {label}
                    </CalculationBtn>
                ))}
                {checked === 'different' && (
                    <input
                        type="number"
                        min="1"
                        max="10000"
                        step="1"
                        name="quantity"
                        className={classNames("input quantity", { invalid: !!customValueError })}
                        placeholder="Zadejte množství"
                        value={customValue || ''}
                        onChange={(e) => setProperty(PropertyCustomQuantity, e.target.value)}
                        onFocus={() => resetError(StateProperties.PaperQuantityCustom)}
                    />
                )}
            </div>
            {contactUs && (
                <div className="contact-us">
                    <p>
                        <strong>Potřebujete větší množství letáků?</strong>
                    </p>
                    <p>Pošlete nám svou poptávku na <a href="mailto:brko@tiskarnabrko.cz">brko@tiskarnabrko.cz</a> a my vám připravíme individuální nabídku šitou na míru!</p>
                </div>
            )}
        </div>
    );
})
