import React, { FC } from "react"
import { useStore } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { CalculationBtn } from "./calculationBtn"
import { calculationStore, StateProperties } from "../../stores/calculationStore"

const InputName = StateProperties.PaperSize;

const BUTTONS = [
  { value: "a6", label: "A6", desc: "(105\u00A0×\u00A0148\u00A0mm)" },
  { value: "a5", label: "A5", desc: "(148\u00A0×\u00A0210\u00A0mm)" },
  { value: "a4", label: "A4", desc: "(210\u00A0×\u00A0297\u00A0mm)" },
  { value: "a3", label: "A3", desc: "(297\u00A0×\u00A0420\u00A0mm)" },
  { value: "dl", label: "DL", desc: "(99\u00A0×\u00A0210\u00A0mm)" },
]

export const CalculationPaperSize: FC = () => {
  const { checked, setProperty } = useStore(
    calculationStore,
    useShallow(s => ({
      checked: s.state[InputName],
      setProperty: s.setProperty,
    }))
  )

  const handleChange = (value: string) => {
    setProperty(InputName, value)
  }

  return (
    <div className="section-items">
      {BUTTONS.map(({ value, label, desc }) => (
        <CalculationBtn
          key={`${InputName}-${value}`}
          id={`${InputName}-${value}`}
          name={InputName}
          value={value}
          isActive={value === checked}
          onChange={handleChange}
          className="btn-radio-simple-item"
        >
          <span className="label">{label}</span>
          <span className="description">{desc}</span>
        </CalculationBtn>
      ))}
    </div>
  )
}
