import { useEffect, useRef, useState } from "react"

export function usePageContainerRef() {
  const [version, setVersion] = useState(0)
  const pageContainerRefDiv = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const navElements = document.getElementsByClassName("navbar")
    if (navElements.length === 0) {
      return
    }

    pageContainerRefDiv.current = navElements[0].parentElement
    setVersion(version + 1)
  }, [])

  return pageContainerRefDiv.current
}
