import React, { FC } from "react"
import classNames from "classnames"

type Props = {
  type?: string
  name: string
  label: string
  value: string
  error: string
  required?: boolean
  onChange: (value: string, name: string) => void
  onFocus?: (name: string) => void
}

export const CalculationInput: FC<Props> = ({ type = "text", name, label, error, required, onChange, onFocus }) => {
  return (
    <label className="input-label">
      <div className="input-label-container">
        <span>{label}:</span>
        <span className="error">
          {error}
        </span>
      </div>
      <input type={type} name={name} className={classNames("input", { invalid: error })}
        required={required} onChange={(e) => onChange(e.target.value, name)} onFocus={() => onFocus && onFocus(name)} />
    </label>
  )
}
