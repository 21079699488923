import React, { FC, useEffect, useRef, useState } from "react"
import { uniqueId } from "lodash"
import { useStore } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { calculationStore } from "../../stores/calculationStore"

export const CalculationUpload: FC = () => {
  const [fileInputVersion, setFileInputVersion] = useState(0)
  const uploadRef = useRef<HTMLInputElement | null>(null)
  const { files, filesError, addFiles, removeFile } = useStore(
    calculationStore,
    useShallow(s => ({
      files: s.files,
      filesError: s.filesError,
      addFiles: s.addFiles,
      removeFile: s.removeFile,
    }))
  )

  useEffect(() => {
    setFileInputVersion(fileInputVersion + 1)
  }, [files])

  const handleUploadClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()

    uploadRef.current?.click()
  }

  const handleAddFiles = (files: File[]) => {
    const fs = files.map(f => ({
      file: f,
      id: uniqueId("file_"),
    }))
    addFiles(fs)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const files = Array.from(e.dataTransfer?.files || [])
    if (files.length === 0) {
      return
    }

    handleAddFiles(files)
  }

  const handleChoose = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      handleAddFiles(Array.from(e.target.files))
    }
  }

  return (
    <div className="section-upload">
      <p>Připraveni na tisk? Nahrajte své soubory jednoduše zde.</p>
      <p>Nemáte data ještě hotová? Nevadí!</p>
      <p>Pošlete nám je později na <a href="mailto:brko@tiskarnabrko.cz">brko@tiskarnabrko.cz</a> a my se o vše postaráme.</p>
      <p>&nbsp;</p>
      {files.length ? (
        <>
          <div>Přiložené soubory:</div>
          <ul>
            {files.map(f => (
              <li key={f.id}>
                {f.file.name}
                <button className="btn-del" onClick={() => removeFile(f.id)}>
                  <span className="fa fa-times"></span>
                </button>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {filesError.length ? <div className="section-upload--error">
        {filesError.map((e, i) => (
          <div key={i}>{e}</div>
        ))}
        <p>&nbsp;</p>
      </div> : null}
      <div>
        <input
          key={fileInputVersion.toString()}
          ref={uploadRef}
          type="file"
          name="files"
          multiple
          onChange={handleChoose}
        />
        <div
          onDragOver={e => e.preventDefault()}
          onDrop={handleDrop}
          className="upload-zone"
        >
          <div>
            Sem přetáhněte soubory nebo{" "}
            <a href="" onClick={handleUploadClick}>
              vyberte soubory
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  )
}
