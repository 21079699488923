import { createStore } from "zustand"

const FILES_SIZE_LIMIT = 50 * 1024 * 1024
const FILES_SIZE_EXCEEDED_ERROR = [
  "Součet velikostí všech souborů může být maximálně 50MB.",
  "Potřebujete-li nahrát více souborů k tisku kontaktujte nás.",
]

export const DifferentQuantityValue = "different"

export const StateProperties = {
  PaperFolding: "paperFolding",
  PaperPrint: "paperPrint",
  PaperSize: "paperSize",
  PaperType: "paperType",
  PaperQuantity: "paperQuantity",
  PaperQuantityCustom: "paperQuantityCustom",
  PaperQuantityCustomError: "paperQuantityCustomError",
}

function getDefaultState() {
  return {
    [StateProperties.PaperFolding]: "no-folding",
    [StateProperties.PaperPrint]: "one-side",
    [StateProperties.PaperSize]: "a5",
    [StateProperties.PaperType]: "bright_white_smooth_130g",
    [StateProperties.PaperQuantity]: "100",
    [StateProperties.PaperQuantityCustom]: "",
  }
}

type CalculationFile = {
  id: string
  file: File
}

type CalculationStore = {
  state: { [propertyName: string]: string }
  files: CalculationFile[]
  filesError: string[]
  contactUs: boolean
  setContactUs: (value: boolean) => void
  setProperty: (propertyName: string, value: string) => void
  addFiles: (files: CalculationFile[]) => void
  removeFile: (id: string) => void
  validate: () => boolean
  resetError: (propertyName: string) => void
}

export const calculationStore = createStore<CalculationStore>((set, get) => ({
  state: getDefaultState(),
  files: [],
  filesError: [],
  contactUs: false,
  setContactUs: (value: boolean) => {
    set({ contactUs: value })
  },
  setProperty: (propertyName: string, value: string) => {
    set({ state: { ...get().state, [propertyName]: value } })
  },
  addFiles: (files: CalculationFile[]) => {
    const currentFiles = get().files

    const newFiles = [...currentFiles, ...files]
    const filesError = isFilesSizeExceeded(newFiles) ? FILES_SIZE_EXCEEDED_ERROR : []
    set({ files: [...currentFiles, ...files], filesError })
  },
  removeFile: (id: string) => {
    const currentFiles = get().files

    const newFiles = currentFiles.filter(f => f.id !== id)
    set({ files: newFiles, filesError: [] })

    if (isFilesSizeExceeded(newFiles)) {
      set({
        filesError: FILES_SIZE_EXCEEDED_ERROR,
      })
    }
  },
  validate: () => {
    const state = get().state
    let result = {}

    if (
      state[StateProperties.PaperQuantity] === DifferentQuantityValue &&
      !state[StateProperties.PaperQuantityCustom]
    ) {
      result = {
        ...result,
        [StateProperties.PaperQuantityCustomError]: "Zadejte množství",
      }
    }

    const hasError = Object.keys(result).length > 0
    if (hasError) {
      set({ state: { ...state, ...result } })
    }

    return !hasError
  },
  resetError: (propertyName: string) => {
    const stateIndex = `${propertyName}Error`
    const val = get().state[stateIndex]
    if (!val) {
      return
    }
    set({ state: { ...get().state, [stateIndex]: "" } })
  },
}))

function isFilesSizeExceeded(files: CalculationFile[]): boolean {
  const totalSize = files.reduce((acc, f) => acc + f.file.size, 0)
  return totalSize > FILES_SIZE_LIMIT
}
