import { createStore } from "zustand"

export const StateProperties = {
    name: "name",
    nameError: "nameError",
    surname: "surname",
    surnameError: "surnameError",
    email: "email",
    emailError: "emailError",
    phone: "phone",
    phoneError: "phoneError",
}

export type StatePropertiesType = keyof typeof StateProperties

type CalculationContactStore = {
    state: { [K in StatePropertiesType]: string }
    setProperty: (propertyName: StatePropertiesType, value: string) => void
    resetError: (propertyName: StatePropertiesType) => void
    validate: () => boolean
}

export const calculationContactStore = createStore<CalculationContactStore>((set, get) => ({
    state: {
        name: "",
        nameError: "",
        surname: "",
        surnameError: "",
        email: "",
        emailError: "",
        phone: "",
        phoneError: "",
    },
    setProperty: (propertyName, value) => {
        set({ state: { ...get().state, [propertyName]: value } });
    },
    resetError: (propertyName) => {
        const stateIndex = `${propertyName}Error` as StatePropertiesType;
        const val = get().state[stateIndex];
        if (!val) {
            return;
        }
        set({ state: { ...get().state, [stateIndex]: "" } });
    },
    validate: () => {
        const { name, surname, email } = get().state;

        let result = {};

        if (!name) {
            result = { ...result, [StateProperties.nameError]: "Zadejte své jméno" };
        }

        if (!surname) {
            result = { ...result, [StateProperties.surnameError]: "Zadejte své přijmení" };
        }

        if (!email) {
            result = { ...result, [StateProperties.emailError]: "Zadejte svůj email" };
        }

        const hasError = Object.keys(result).length > 0;
        if (hasError) {
            set({ state: { ...get().state, ...result } });
        }

        return !hasError;
    }
}))
