import React, { FC, forwardRef } from "react"
import { useStore } from "zustand"
import { CalculationInput as Input } from "./calculationInput"
import {
  calculationContactStore,
  StateProperties,
  StatePropertiesType,
} from "../../stores/calculationContactStore"
import { useShallow } from "zustand/react/shallow"

type Props = {}

export const CalculationContact = forwardRef<HTMLDivElement, Props>((_, ref) => {
  const {
    name,
    nameError,
    surname,
    surnameError,
    email,
    emailError,
    phone,
    phoneError,
    setProperty,
    resetError,
  } = useStore(
    calculationContactStore,
    useShallow(s => ({
      name: s.state.name,
      nameError: s.state.nameError,
      surname: s.state.surname,
      surnameError: s.state.surnameError,
      email: s.state.email,
      emailError: s.state.emailError,
      phone: s.state.phone,
      phoneError: s.state.phoneError,
      setProperty: s.setProperty,
      resetError: s.resetError,
    }))
  )

  const handleOnChange = (value: string, name: string) => {
    setProperty(name as StatePropertiesType, value)
  }

  const handleFocus = (name: string) => {
    resetError(name as StatePropertiesType)
  }

  return (
    <div ref={ref} className="contact">
      <Input
        name={StateProperties.name}
        label="Jméno"
        value={name}
        error={nameError}
        onChange={handleOnChange}
        onFocus={handleFocus}
      />
      <Input
        name={StateProperties.surname}
        label="Přijmení"
        value={surname}
        error={surnameError}
        onChange={handleOnChange}
        onFocus={handleFocus}
      />
      <Input
        name={StateProperties.email}
        label="Email"
        value={email}
        error={emailError}
        onChange={handleOnChange}
        onFocus={handleFocus}
      />
      <Input
        name={StateProperties.phone}
        label="Telefon"
        value={phone}
        error={phoneError}
        onChange={handleOnChange}
      />
    </div>
  )
})
