import React, { FC } from "react";
import { useStore } from "zustand";
import { CalculationBtn } from "./calculationBtn";
import { useShallow } from "zustand/react/shallow";
import { calculationStore, StateProperties } from "../../stores/calculationStore";

const InputName = StateProperties.PaperPrint;

const BUTTONS = [
    { value: 'one-side', label: "Jednostranný" },
    { value: 'two-side', label: "Oboustranný" },
];

export const CalculationPaperPrint: FC = () => {
    const { checked, setProperty } = useStore(
        calculationStore,
        useShallow(s => ({
          checked: s.state[InputName],
          setProperty: s.setProperty,
        }))
      )
    
      const handleChange = (value: string) => {
        setProperty(InputName, value)
      }

    return (
        <div className="section-items">
            {BUTTONS.map(({ value, label }) => (
                <CalculationBtn
                    key={`${InputName}-${value}`}
                    id={`${InputName}-${value}`}
                    name={InputName}
                    value={value}
                    isActive={value === checked}
                    onChange={handleChange}
                    className="btn-radio-simple-item"
                >
                    {label}
                </CalculationBtn>
            ))}
        </div>
    );
}
