import classNames from "classnames"
import React, { FC, ReactNode } from "react"

type Props = {
  id: string
  name: string
  value: string
  isActive: boolean
  children: ReactNode
  className?: string
  onChange: (value: string) => void
}

export const CalculationBtn: FC<Props> = ({
  id,
  name,
  value,
  isActive,
  children,
  className,
  onChange,
}) => {
  return (
    <div className={classNames(className, 'btn-radio', { 'active': isActive })}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={isActive}
        onChange={() => onChange(value)}
        className="btn-radio-input"
      />
      <label htmlFor={id} className="btn-radio-label">
        {children}
      </label>
    </div>
  )
}
